import React from 'react';

function setupHeader() {
  return (
    <div>
      <h1>Your Resume Book Profile</h1>
      <h3>Please provide the following information to show to companies:</h3>
    </div>
  );
}

export default setupHeader;