import React from 'react';
import StudentProfileList from './StudentProfileList';

function StudentProfileListPage() {
    return (
        <div className="resumes-page">
            <StudentProfileList />
        </div>
    );
}

export default StudentProfileListPage;


